<script>
export default {
  name: 'Rule'
}
</script>

<template>
  <router-view />
</template>

<style scoped lang="scss">

</style>
