<script>
import { changeIntegralRuleStatus, getIntegralRuleList } from './api'

import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue'

const columns = [
  {
    width: '60px',
    align: 'center',
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    width: '120px',
    align: 'center',
    title: '规则名称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    width: '220px',
    align: 'center',
    title: '规则内容',
    key: 'rule_description',
    dataIndex: 'rule_description'
  },
  {
    width: '160px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'IntegralRuleList',
  components: { Pagination },
  data() {
    return {
      loading: false,
      downloading: false,

      columns,
      data: [],
      pagination: { ...getPagination() }
    }
  },
  created() {
    this.onSearch()
  },
  methods: {
    onReset() {
      this.onSearch()
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize)
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize)
    },
    onSizeChange(current, size) {
      this.getData(1, size)
    },
    async getData(page, pageSize) {
      page = page || 1
      pageSize = pageSize || this.pagination.defaultPageSize

      if(this.loading) {
        return
      }
      this.loading = true

      const params = {
        page,
        per_page: pageSize
      }

      const data = await getIntegralRuleList(params).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        return
      }
      this.data = data?.data || []

      const pagination = data?.meta?.pagination || {}
      this.pagination = updatePagination(this.pagination, pagination)
    },


    onCreate() {
      this.$router.push({ path: '/index/integral/integral/rule/create' })
    },
    onEdit(record) {
      this.$router.push({ path: '/index/integral/integral/rule/detail', query: { id: record.id } })
    },
    onActive(record) {
      this.$confirm({
        title: '提示',
        content: '确认上架该积分规则？',
        onOk: async () => {
          if(this.loading) {
            return
          }
          this.loading = true
          const data = await changeIntegralRuleStatus({ status: 'on', id: record.id }).finally(() => {
            this.loading = false
          })

          if(!data || data.error_code) {
            this.$message.error(data?.message || '上架失败')
            return
          }
          record.status = 1
          this.$message.success('上架成功')
        }
      })
    },
    onDeactive(record) {
      this.$confirm({
        title: '提示',
        content: '确认下架该积分规则？',
        onOk: async () => {
          if(this.loading) {
            return
          }
          this.loading = true
          const data = await changeIntegralRuleStatus({ status: 'off', id: record.id }).finally(() => {
            this.loading = false
          })

          if(!data || data.error_code) {
            this.$message.error(data?.message || '下架失败')
            return
          }
          record.status = 0
          this.$message.success('下架成功')
        }
      })
    },
    onDelete(record) {
      this.$confirm({
        title: '提示',
        content: '确认删除该积分规则？',
        onOk: async () => {
          if(this.loading) {
            return
          }
          this.loading = true
          const data = await changeIntegralRuleStatus({ status: 'del', id: record.id }).finally(() => {
            this.loading = false
          })

          if(!data || data.error_code) {
            this.$message.error(data?.message || '删除失败')
            return
          }

          this.getData(this.data.length > 1 ? this.pagination.current : Math.max(1, this.pagination.current - 1))
          this.$message.success('删除成功')
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="flex flex-end">
      <a-button @click="onCreate()">添加积分规则</a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="mt-20 table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button v-if="record.status === 0" type="link" @click="onActive(record)">上架</a-button>
        <a-button v-else type="link" @click="onDeactive(record)">下架</a-button>
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button type="link" @click="onDelete(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange" />
      </template>
    </a-table>
  </div>
</template>

<style scoped lang="scss">

</style>
